<template>
  <b-card body-class="p-0" class="mb-50">
    <b-row no-gutters style="flex-wrap: nowrap;">
      <b-col :class="'bg-' + noteInfo.color" class="pr-50 pr-lg-1 rounded-left" cols="auto"/>
      <b-col class="d-flex align-items-center justify-content-between mx-25 mx-sm-50 mx-lg-1 my-50" cols="auto">
        <b-card-img
            v-if="noteInfo.image"
            :alt="noteInfo.image.alt"
            :src="noteInfo.image.src"
            class="note-icon"
            left
        />
      </b-col>

      <b-col :class="note.kind === 'Note' ? 'flex-grow-1' : ''" class="d-flex flex-column justify-content-center mx-50">
        <div v-if="noteInfo.text1 || noteInfo.text2" class="d-flex align-items-center justify-content-start flex-wrap w-100 my-1">
          <div class="font-weight-bolder font-medium-2">
            {{ noteInfo.text1 }}
          </div>
          <div class="pl-50 text-left">{{ noteInfo.text2 }}</div>
        </div>
        <viewer v-if="noteInfo.picture">
          <b-card-img
              :alt="noteInfo.picture.alt"
              :src="noteInfo.picture.src"
              style="max-height: 200px;"
              class="w-100 mb-1"
          />
        </viewer>
      </b-col>

      <b-col :class="note.kind === 'Note' ? 'flex-grow-0' : 'flex-grow-1'" class="d-flex flex-column justify-content-between align-items-end pr-50 py-50">
        <div v-if="note.kind === 'Note'">
          <feather-icon
              v-if="note.reminders.length > 0 && new Date(note.reminders[0].date) >= new Date()"
              class="text-primary"
              icon="BellIcon"
              size="16"
          />
          <b-button
              class="py-0 px-0"
              variant="flat-primary"
              @click="editNote = true"
          >
            <feather-icon
                icon="MoreHorizontalIcon"
                size="24"
            />
          </b-button>
        </div>
        <div v-else :class="'text-' + noteInfo.color" class="font-italic font-small-3 text-right">
          Auto-générée
          <b-card-img
              v-if="noteInfo.image2.src"
              :alt="noteInfo.image2.alt"
              :src="noteInfo.image2.src"
              class="note-icon-small"
              left
          /></div>
        <div class="font-weight-bold font-medium-1"> {{
            new Date(note.date).toLocaleDateString('fr-FR')
          }}
        </div>
        <div v-if="noteInfo.linkedObject" class="text-right"> {{ noteInfo.linkedObject.name }}</div>
      </b-col>
    </b-row>

    <b-modal
        v-model="editNote"
        centered
        hide-footer
        scrollable
        size="lg"
        title="Editer la note"
    >
      <NoteEdit :note="note" :linkedObject="noteInfo.linkedObject" @close="editNote = false"/>
    </b-modal>
  </b-card>
</template>

<script>
import { BButton, BCard, BCardImg, BCol, BModal, BRow } from 'bootstrap-vue'
import NoteEdit from '@/components/notes/NoteEdit'

export default {
  components: {
    BModal,
    BCard,
    BButton,
    BRow,
    BCol,
    BCardImg,
    NoteEdit,
  },
  props: {
    note: {
      required: true,
      type: Object
    },
  },
  data() {
    return {
      editNote: false,
    }
  },
  computed: {
    noteInfo() {
      let infos = {
        color: 'secondary',
        image: {
          src: 'tools/shovel',
          alt: 'icone pelle'
        },
        image2: {},
      }
      if (this.note.pictos && this.note.pictos[0]) {
        infos.image.src = 'plants/' + this.note.pictos[0].name.replace('.svg', '')
        infos.image.alt = 'Picto de ' + this.note.plant_name
      }
      switch (this.note.kind) {
        case 'State':
          infos.text1 = this.note.plant_name
          switch (this.note.state) {
            case 'DE':
              infos.text2 = 'Morte'
              infos.image2.src = 'other/invasive'
              infos.image2.alt = 'icone retiré'
              break
            case 'RE':
              infos.text2 = 'Retirée'
              infos.image2.src = 'other/invasive'
              infos.image2.alt = 'icone retiré'
              break
            case 'SO':
              infos.text2 = 'Semé'
              infos.image2.src = 'other/sowing_hand'
              infos.image2.alt = 'icone semer'
              break
            case 'AC':
              infos.text2 = 'Plant récupérée'
              infos.image2.src = 'plants/default_leaf'
              infos.image2.alt = 'icone feuille'
              break
            case 'PL':
              infos.text2 = 'Plantée/ Repiquée'
              infos.image2.src = 'tools/garden_trowel'
              infos.image2.alt = 'icone transplantoire'
              break
            case 'TR':
              infos.text2 = 'Taillée'
              infos.image2.src = 'tools/shear'
              infos.image2.alt = 'icone arrosoire'
              break
          }
          break
        case 'Harv':
          infos.text1 = this.note.plant_name
          infos.text2 = 'Récolte : '
          if (this.note.mass) {
            infos.text2 += this.note.mass + ' kg'
          }
          if (this.note.mass && this.note.number) {
            infos.text2 += ' et '
          }
          if (this.note.number) {
            infos.text2 += this.note.number + " organe(s)"
          }
          infos.image2.src = 'tools/basket'
          infos.image2.alt = 'icone panier'
          break
        case 'Mulch':
          infos.text1 = 'Paillage'
          infos.text2 = this.$store.state.garden.mulchOptions.find(mulch => mulch.value === this.note.composition).text
          infos.color = 'dark'
          infos.image.src = 'tools/shovel'
          infos.image.alt = 'icone pelle'
          break
        case 'Fert':
          infos.text1 = 'Fertilisation'
          infos.text2 = this.$store.state.garden.fertilizationOptions.find(fert => fert.value === this.note.composition).text
          infos.color = 'dark'
          infos.image.src = 'tools/shovel'
          infos.image.alt = 'icone pelle'
          break
        case 'Note':
          infos.text2 = this.note.text ? '"' + this.note.text + '"' : null
          infos.image.src = 'other/pen'
          infos.image.alt = 'icone stylo'
          infos.color = 'primary'
          if (this.note.photo) {
            infos.picture = {
              src: null,
              alt: ''
            }
            infos.picture.src = process.env.VUE_APP_BACKEND_URL + '/..' + this.note.photo_url + '&size=600'
            infos.picture.alt = 'photo utilisateur'
          }
          break
      }

      if(this.note.quantity > 1) {
        infos.text1 += ' x' + this.note.quantity.toString()
      }

      if (this.note.vegetable_garden) {
        infos.linkedObject = this.$store.state.garden.vgardens.find(vgarden => vgarden.id === this.note.vegetable_garden)
      } else if (this.note.garden) {
        infos.linkedObject = this.$store.state.garden.gardens.find(garden => garden.id === this.note.garden)
      }
      let img = ''
      try {
        img = require('@/assets/images/icons/' + infos.image.src + '.svg')
      } finally {
        // do nothing
      }
      infos.image.src = img

      let img2 = ''
      if (infos.image2.src) {
        try {
          img2 = require('@/assets/images/icons/' + infos.image2.src + '.svg')
        } finally {
          // do nothing
        }
        infos.image2.src = img2
      }
      return infos
    }
  }
}
</script>

<style lang="scss">
.note-icon {
  height: 40px;
  width: 40px;
  @media (min-width: 768px) {
    height: 60px;
    width: 60px;
  }
}

.note-icon-small {
  height: 20px;
  width: 20px;
  @media (min-width: 768px) {
    height: 25px;
    width: 25px;
  }
}
</style>
