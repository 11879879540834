<template>
  <div class="max-w-lg mr-auto ml-auto text-justify">
    <div class="d-flex justify-content-center align-items-center">
      <JayaTitle titleName="Carnet de notes"/>
      <b-button
          :variant="isFilters ? 'dark' : 'outline-primary'"
          class="btn-icon ml-50 mb-25 rounded-circle p-50"
          size="sm"
          @click="filterModal = true"
      >
        <feather-icon icon="FilterIcon" size="16"/>
      </b-button>
    </div>
    <div class="d-flex justify-content-center">
      <b-button
          class="my-1"
          size="lg"
          variant="primary"
          @click="newNote = true"
      >
        <feather-icon
            icon="PenToolIcon"
            size="18"
        />
        <span class="align-middle ml-50">Ajouter une note</span>
      </b-button>
    </div>

    <div v-if="fetchPayload && fetchPayload.end_date" class="d-flex mb-50">
      <jaya-divider class="flex-grow-1 pr-50" color="dark" :text="fetchPayload.end_date.toLocaleDateString('fr-FR')"/>
      <b-button
          size="sm"
          pill
          variant="primary"
          @click="oneMoreMonthEnd">
        <feather-icon
            icon="PlusIcon"/>
      </b-button>
    </div>

    <transition-group class="incard" name="list">
      <p v-if="notes.length === 0" key="noteNull" class="text-center"> Aucune note </p>
      <NoteCard v-else v-for="(note, index) in notes" :key="index" ref="noteCards" :note="note"/>
    </transition-group>

    <div v-if="fetchPayload && fetchPayload.start_date" class="d-flex mb-50">
      <jaya-divider class="flex-grow-1 pr-50" color="dark" :text="fetchPayload.start_date.toLocaleDateString('fr-FR')"/>
      <b-button
          size="sm"
          pill
          variant="primary"
          @click="oneMoreMonthStart">
        <feather-icon
            icon="PlusIcon"/>
      </b-button>
    </div>

    <jaya-divider color="transparent"/>

    <b-modal
        v-model="newNote"
        centered
        hide-footer
        scrollable
        size="lg"
        title="Nouvelle note"
    >
      <NoteEdit new @new-note="removeAllFilters()" @close="newNote = false"/>
    </b-modal>

    <b-modal
        v-model="filterModal"
        centered
        hide-footer
        scrollable
        title="Filtres"
    >
      <b-row class="d-flew flex-column align-items-center my-1">
        <b-form-checkbox
            v-model="filters.only_manual"
            class="custom-control-primary"
        >
          Notes personnelles seulement
        </b-form-checkbox>
      </b-row>

      <b-row class="d-flew flex-column align-items-center mt-1">
        <b-col cols="auto">
          <b-form-checkbox
              v-model="filters.useDates"
              class="custom-control-primary"
          >
            Chercher entre deux dates
          </b-form-checkbox>
        </b-col>
        <b-row v-if="filters.useDates" class="w-100 mt-1">
          <b-col cols="12" lg="6">
            <b-col cols="4">Début</b-col>
            <flat-pickr v-model="filters.start_date"
                        :config="{maxDate: filters.end_date}"
                        :key="dateKey"
                        @on-change="updateDateComponent"
                        class="form-control"
            />
          </b-col>
          <b-col cols="12" lg="6">
            <b-col cols="4">Fin</b-col>
            <flat-pickr v-model="filters.end_date"
                        :config="{minDate: filters.start_date}"
                        :key="dateKey + 1"
                        @on-change="updateDateComponent"
                        class="form-control"
            />
          </b-col>
        </b-row>
      </b-row>

      <b-row class="d-flex justify-content-center my-1">
        <b-form-select v-model="filters.filterObjectLinked">
          <b-form-select-option v-for="(garden, index) of userGardens"
                                :key="index"
                                :class="filters.filterObjectLinked === garden.value ? 'text-primary' : ''"
                                :disabled="garden.disabled"
                                :value="garden.value"
          >
            {{ garden.isVGarden ? '---' : '' }}
            {{ garden.text }}
          </b-form-select-option>
        </b-form-select>
      </b-row>

      <b-row class="w-100 justify-content-around wrap">
        <b-col class="flex-centered mt-1" cols="auto" md="auto">
          <b-button class="float-left" variant="outline-primary" @click="removeAllFilters">
            Tout retirer
          </b-button>
        </b-col>
        <b-col class="flex-centered mt-1" cols="auto" md="auto">
          <b-button :disabled="!isFilters" class="float-right" variant="primary" @click="filterValidated">
            Filtrer
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import JayaTitle from '@/components/global/JayaTitle.vue'
import NoteCard from '@/components/notes/NoteCard.vue'
import NoteEdit from '@/components/notes/NoteEdit.vue'
import { BButton, BCol, BFormCheckbox, BFormSelect, BFormSelectOption, BModal, BRow } from 'bootstrap-vue'
import FlatPickr from 'vue-flatpickr-component'

export default {
  components: {
    JayaTitle,
    NoteCard,
    NoteEdit,
    BButton,
    BModal,
    BRow,
    BCol,
    BFormSelect,
    BFormSelectOption,
    BFormCheckbox,
    FlatPickr,
  },
  data() {
    return {
      newNote: false,
      fetchGarden: null,
      fetchVGarden: null,
      fetchOffset: 0,
      fetchLimit: 10,
      fetchNext: null,
      waitScroll: false,
      filterModal: false,
      filters: {
        useFilters: false,
        filterObjectLinked: this.$store.state.garden.gardens.find(garden => {
          return garden.is_active
        }),
        only_manual: false,
        useDates: false,
        start_date: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
        end_date: new Date(),
      },
      dateKey: 0,
    }
  },
  props: {
    showDate: {
      type: Date,
      default: null
    },
    associatedGarden: {
      type: Object,
      default: null
    },
  },
  mounted() {
    window.addEventListener('scroll', this.scroll)
    this.fetchGarden = this.activeGarden
    if (this.showDate && this.associatedGarden) {
      this.filters.end_date = this.showDate
      this.filters.start_date = this.showDate
      this.filters.useDates = true
      this.filterObjectLinked = this.associatedGarden
      this.filterValidated()
    } else {
      this.fetchNotes()
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.scroll)
  },
  computed: {
    fetchPayload() {
      let payload = {
        limit: this.fetchLimit,
        offset: this.fetchOffset,
      }
      if (this.isFilters && this.filters.useFilters) {
        if (this.filters.only_manual) {
          payload.only_manual = true
        }
        if (this.filters.useDates) {
          payload.start_date = new Date(this.filters.start_date)
          payload.end_date = new Date(this.filters.end_date)
        }
      }
      if (this.fetchVGarden) {
        payload.vgarden = this.fetchVGarden.id
      } else if (this.fetchGarden) {
        payload.garden = this.fetchGarden.id
      } else {
        return null
      }
      return payload
    },
    notes() {
      return this.$store.state.notes.notes
    },
    activeGarden() {
      return this.$store.state.garden.gardens.find(garden => garden.is_active)
    },
    isFilters() {
      if (this.filters.filterObjectLinked !== this.activeGarden ||
          this.filters.only_manual ||
          this.filters.useDates) {
        return true
      }
      if (this.filters.filterObjectLinked &&
          (this.fetchVGarden ||
          this.fetchGarden !== this.activeGarden)) {
        return true
      }
      return false
    },
    userGardens() {
      let gardensArray = [{
        value: null,
        text: 'Sélectionnez un jardin ou un potager comme filtre',
        disabled: true
      }]
      for (const garden of this.$store.state.garden.gardens) {
        let localAuth = garden.local_authority ? ' ' + garden.local_authority_infos.name : ''
        gardensArray.push({
          value: garden,
          text: garden.name + localAuth,
        })
        for (const vGarden of this.$store.state.garden.vgardens.filter(vgarden => vgarden.garden === garden.id)) {
          gardensArray.push({
            value: vGarden,
            text: vGarden.name,
            isVGarden: true,
          })
        }
      }
      return gardensArray
    },
  },
  methods: {
    scroll() {
      if (this.fetchNext && document.body.scrollHeight - window.scrollY - window.innerHeight < 50) {
        this.loadNext()
      }
    },
    fetchNotes() {
      this.fetchOffset = 0
      this.$store.dispatch('notes/fetchNotes', this.fetchPayload)
          .then((response) => {
            this.fetchNext = response.next
          })
          .catch(() => {
            this.$notify({
              time: 4000,
              color: 'danger',
              title: 'Erreur',
              text: 'Une erreur est survenue lors de la récupération des notes'
            })
          })
    },
    loadNext() {
      if (!this.waitScroll) {
        this.waitScroll = true
        this.fetchOffset = this.fetchOffset + this.fetchLimit
        this.$store.dispatch('notes/fetchNotes', this.fetchPayload)
            .then((nextResponse) => {
              this.fetchNext = nextResponse.next
              this.waitScroll = false
            })
            .catch(() => {
              this.$notify({
                time: 4000,
                color: 'danger',
                title: 'Erreur',
                text: 'Une erreur est survenue lors de la récupération des notes'
              })
              this.waitScroll = false
            })
      }
    },
    removeAllFilters() {
      this.filters.useFilters = false
      this.filters.filterObjectLinked = this.activeGarden
      this.filters.only_manual = false
      this.filters.useDates = false
      this.fetchNotes()
      this.filterModal = false
    },
    filterValidated() {
      this.filters.useFilters = true
      if (this.filters.filterObjectLinked) {
        if (this.filters.filterObjectLinked.vgarden_plants) {
          this.fetchVGarden = this.filters.filterObjectLinked
        } else {
          this.fetchGarden = this.filters.filterObjectLinked
          this.fetchVGarden = null
        }
      }
      this.filterModal = false
      this.fetchNotes()
    },
    updateDateComponent() {
      this.dateKey = this.dateKey + 1
    },
    oneMoreMonthEnd() {
      this.filters.end_date = new Date(this.filters.end_date).setMonth(new Date(this.filters.end_date).getMonth() + 1)
      this.filterValidated()
    },
    oneMoreMonthStart() {
      this.filters.start_date = new Date(this.filters.start_date).setMonth(new Date(this.filters.start_date).getMonth() - 1)
      this.filterValidated()
    }
  },
  watch: {
    fetchNext(value) { //get at least 10 note cards
      if (!!value && this.notes.length < 10) {
        this.loadNext()
      }
    },
  }
}
</script>
